export default {
  status_codes: [
    { headerName: "Status Code", field: "code" },
    { headerName: "Description", field: "description" },
    { headerName: "Reason", field: "reason" },
    {
      headerName: "Violation",
      field: "is_violation",
      __metadata__: { type: Boolean },
      valueFormatter: ({ value }) => {
        return value ? "Violation Code" : "Non-Violation Code"
      },
    },
    {
      headerName: "Active",
      field: "is_active",
      __metadata__: { type: Boolean },
      valueFormatter: ({ value }) => {
        return value ? "Activated" : "Deactivated"
      },
    },
  ],
  type_codes: [
    { headerName: "Type Code", field: "code" },
    { headerName: "Description", field: "description" },
    {
      headerName: "Active",
      field: "is_active",
      __metadata__: { type: Boolean },
      valueFormatter: ({ value }) => {
        return value ? "Activated" : "Deactivated"
      },
    },
  ],
  communications: [{ headerName: "Department Communication", field: "text" }],
}
