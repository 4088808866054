<template>
  <div class="w-100">
    <div v-if="col.__metadata__.type" class="input-group">
      <input
        v-if="col.__metadata__.type === 'number'"
        :id="id"
        :value="stringValue"
        type="number"
        class="form-control"
        :class="{ 'border border-success': !!value.transformed }"
        :aria-describedby="col.field"
        :step="col.field === 'transaction_amount' ? 0.01 : 1"
        @input="emitValue"
        @change="emitValue"
        @focus="focus = true"
        @blur="focus = false"
        @keypress="$emit('keypress', $event)"
      />
      <input
        v-else-if="['date', 'datetime'].includes(col.__metadata__.type)"
        :id="id"
        :value="stringValue"
        type="date"
        class="form-control"
        :class="{ 'border border-success': !!value.transformed }"
        :aria-describedby="col.field"
        @input="emitValue"
        @change="emitValue"
        @focus="focus = true"
        @blur="focus = false"
        @keypress="$emit('keypress', $event)"
      />
      <input
        v-else
        :id="id"
        :value="stringValue"
        autocomplete="off"
        :list="id + '-list'"
        type="text"
        class="form-control"
        :class="{ 'border border-success': !!value.transformed }"
        :aria-describedby="col.field"
        :title="title"
        :pattern="repeatingPattern"
        @input="emitValue"
        @change="emitValue"
        @focus="focus = true"
        @blur="focus = false"
        @keypress="$emit('keypress', $event)"
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary"
          type="button"
          aria-label="Clear input"
          title="Clear input"
          @click="clear"
        >
          <span class="close">&times;</span>
        </button>
      </div>
    </div>
    <Autocomplete
      v-if="['string', 'sample'].includes(col.__metadata__.type)"
      :id="id + '-list'"
      :field="col.field"
      :value="stringValue"
      :focus="focus"
    ></Autocomplete>
  </div>
</template>

<script>
import Autocomplete from "./Autocomplete"
export default {
  components: {
    Autocomplete,
  },
  props: {
    id: { type: String, required: true },
    col: { type: Object, required: true },
    value: { type: [String, Array], required: true },
    title: { type: String, default: null },
    pattern: { type: String, default: null },
  },
  data() {
    return {
      focus: false,
    }
  },
  computed: {
    stringValue() {
      return Array.isArray(this.value) ? this.value.join(";") : this.value
    },
    repeatingPattern() {
      return this.pattern && `^${this.pattern}(;${this.pattern})*$`
    },
  },
  methods: {
    emitValue(event) {
      let { value } = event.target
      // Allow multiple values separated by semicolons
      if (value?.includes(";")) {
        value = value.split(";").map(x => x.trim())
      }
      this.$emit(event.type, value)
    },
    clear() {
      const event = { target: { value: "" } }
      this.emitValue({ ...event, type: "input" })
      this.emitValue({ ...event, type: "change" })
    },
  },
}
</script>

<style lang="postcss" scoped>
.form-control:invalid {
  box-shadow: none; /* Override browser default */
}
.form-control:invalid:not(:focus) {
  border-color: var(--danger);
}
</style>
