<template>
  <div
    class="w-100"
    style="font-size: 1rem; font-family: var(--font-family-sans-serif);"
    data-testid="link-panel"
  >
    <div
      class="card-header mb-2 border-right d-flex justify-content-between align-items-center"
    >
      <h4 class="mb-0">
        Link
      </h4>
      <PanelControls :show-collapse="false"></PanelControls>
    </div>

    <form class="p-2">
      <ErrorAlert
        v-if="error"
        :error="error"
        @close="error = null"
      ></ErrorAlert>

      <h6 class="ml-1">Selected Transactions</h6>
      <div data-testid="link-selected">
        <ClientSideGrid :row-selection="false" :transactions="transactions" />
      </div>

      <h6 class="mt-3 ml-1">Add to New Group</h6>
      <div class="input-group">
        <input
          v-model="description"
          placeholder="Description"
          class="form-control"
          type="text"
          data-testid="link-description"
        />
        <div class="input-group-append" data-testid="link-create">
          <button
            class="btn btn-primary"
            :disabled="!description"
            data-testid="link-save"
            @click.prevent="createGroup"
          >
            Save
          </button>
        </div>
      </div>

      <h6 class="mt-3 ml-1">Existing Groups</h6>
      <div data-testid="link-groups">
        <GroupsViewer
          id="existing"
          :groups-key="groupsKey"
          action="link"
          :groups-style="{ width: '100%', height: '250px' }"
          :transactions-style="{ width: '100%', height: '500px' }"
          @link="updateGroup"
        />
      </div>
    </form>
  </div>
</template>

<script>
import ClientSideGrid from "./ClientSideGrid"
import GroupsViewer from "./GroupsViewer"
import PanelControls from "./PanelControls"
import ErrorAlert from "./ErrorAlert"
export default {
  name: "LinkPanel",
  components: {
    ClientSideGrid,
    GroupsViewer,
    ErrorAlert,
    PanelControls,
  },
  data() {
    return {
      error: null,
      description: null,
      groupsKey: 0,
      rowNodes: [],
      transactions: [],
    }
  },
  mounted() {
    this.refreshSelected()
    this.params.api.addEventListener("selectionChanged", () => {
      this.refreshSelected()
    })
  },
  methods: {
    refreshSelected() {
      this.rowNodes = this.params.api.getSelectedNodes()
      this.transactions = this.params.api.getSelectedRows()
    },
    /** Create a new group and add all selected transactions to it */
    async createGroup() {
      const path = `/groups/`
      const body = {
        description: this.description,
        transactions: this.transactions.map(txn => txn.id),
      }
      const [error, _response] = await this.$api.post(path, body)
      if (error) {
        this.error = error
      } else {
        this.refreshGroups()
        // Add link icon to main grid
        this.rowNodes.forEach(node => {
          const data = node.data
          data.annotation = { ...data.annotation, groups: true }
          node.updateData(data)
        })
      }
    },
    /** Update or create an annotation for each selected transaction */
    async updateGroup(groupId) {
      const path = `/groups/${groupId}/link/`
      const body = { transactions: this.transactions.map(txn => txn.id) }
      const [error, _response] = await this.$api.put(path, body)
      if (error) {
        this.error = error
      } else {
        this.refreshGroups()
        // Add link icon to main grid
        this.rowNodes.forEach(node => {
          const data = node.data
          data.annotation = { ...data.annotation, groups: true }
          node.updateData(data)
        })
      }
    },
    /** Trigger GridViewer refresh so that groups and transactions reload */
    refreshGroups() {
      this.groupsKey += 1
    },
  },
}
</script>

<style></style>
