<template>
  <div class="w-100">
    <div style="max-height: 400px; overflow-y: auto;">
      <div
        v-for="comment in orderedComments"
        :key="comment.id"
        class="card mb-1"
      >
        <div class="pt-3 pb-2 px-3">
          <div class="mb-1">
            {{ comment.text }}
          </div>
          <div>
            <small>{{ comment.user }} &mdash;</small
            ><small>{{ datetime(comment.created_date) }}</small>
          </div>
        </div>
      </div>
    </div>
    <textarea
      id="comment-input"
      v-model="input"
      class="form-control my-2"
      rows="2"
      placeholder="Enter comment"
    ></textarea>
    <button
      class="btn btn-primary btn-sm w-100"
      :disabled="!input"
      @click.prevent="submit"
    >
      Submit
    </button>
  </div>
</template>

<script>
import formatters from "../utils/formatters"

export default {
  props: {
    comments: { type: [Array], default: () => [] },
  },
  data() {
    return {
      input: "",
    }
  },
  computed: {
    orderedComments() {
      return [...this.comments].sort(c => c.created_date)
    },
  },
  methods: {
    datetime: formatters.datetime,
    submit() {
      this.$emit("submit", this.input)
      this.input = ""
    },
  },
}
</script>

<style></style>
