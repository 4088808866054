import { format, parseISO } from "date-fns"

const parse = s => {
  return s ? parseISO(s) : s
}

const ISODate = (v, options = { parse: true }) => {
  if (options.parse) v = parse(v)
  return v ? format(v, "y-MM-dd") : ""
}

const date = (v, options = { parse: true }) => {
  if (options.parse) v = parse(v)
  return v ? format(v, "MM-dd-y") : ""
}

const datetime = (v, options = { parse: true }) => {
  if (options.parse) v = parse(v)
  return v ? format(v, "M-dd-y K:mm aa") : ""
}

const statementPeriod = (v, options = { parse: true }) => {
  if (options.parse) v = parse(v)
  return v ? format(v, "LLLL yyyy") : ""
}

export default { date, datetime, statementPeriod, ISODate, parse }
