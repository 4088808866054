<template>
  <span>
    <button
      class="btn btn-outline-secondary eds-assist"
      type="button"
      aria-label="Search people"
      title="Search people"
      :disabled="!searchFor || !searchFor.length || searching"
      @click.prevent="getEdsPeople"
    >
      <span
        v-if="searching"
        class="spinner-grow spinner-grow-sm"
        role="status"
        aria-hidden="true"
      />
      <span v-else>
        <SearchIcon class="feather-sm text-dark"></SearchIcon>
      </span>
    </button>
  </span>
</template>

<script>
import SearchIcon from "../assets/search.svg"
export default {
  components: {
    SearchIcon,
  },
  props: {
    searchFor: {
      type: String,
      default: "",
    },
    edsEndpoint: {
      type: String,
      required: true,
    },
    searchField: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      searching: false,
    }
  },
  methods: {
    /** Retrieve eds people assist based on people input fields */
    async getEdsPeople() {
      let params
      if (this.edsEndpoint === "search/") {
        params = this.setUpSearchParams()
      } else {
        params = this.setUpPersonParams()
      }
      if (!params) return
      this.searching = true
      const [error, response] = await this.$api.get(`eds/${this.edsEndpoint}`, {
        params,
      })
      if (error) {
        this.$emit("error", error)
      } else {
        this.$emit("change", {
          data: response.data,
          open: true,
          searchField: this.searchField,
        })
      }
      this.searching = false
    },
    setUpSearchParams() {
      const [last_name, first_name] = this.searchFor
        .split(/[ ,]+/)
        .map(name => name.trim())

      const namesMissing = !first_name || !last_name
      const namesMinLength = last_name.length >= 2 && first_name.length >= 2

      if (namesMissing || !namesMinLength) {
        const error = "Last Name, First Name must both at least 2 characters"
        this.$emit("error", error)
        return
      }

      return { first_name, last_name }
    },
    setUpPersonParams() {
      let uid
      if (this.searchField.includes("netid")) {
        uid = this.searchFor.trim().replace(/[^a-zA-Z]+/g, "")
        if (uid.length < 3) {
          this.$emit("error", "Please enter a valid netid")
          return
        }
      } else {
        uid = this.searchFor.trim().replace(/[^0-9]+/g, "")
        if (uid.length !== 8) {
          this.$emit("error", "Please enter a valid emplid")
          return
        }
      }
      return { uid }
    },
  },
}
</script>

<style lang="postcss" scoped>
.eds-assist {
  width: 44px !important;
}
</style>
