import Vue from "vue"
import Router from "vue-router"

import store from "./store"
import Home from "./views/Home.vue"
const Administration = () => import("./views/Administration")
const Compliance = () => import("./views/Compliance")
const Research = () => import("./views/Research")
const Resources = () => import("./views/Resources")
const ToolbarAdmin = () => import("./components/ToolbarAdmin")
const ToolbarCompliance = () => import("./components/ToolbarCompliance")
const ToolbarResearch = () => import("./components/ToolbarResearch")
const ToolbarResources = () => import("./components/ToolbarResources")

Vue.use(Router)

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      meta: { title: "Home" },
      components: { grid: Home, toolbar: null },
    },
    {
      path: "/administration",
      name: "administration",
      meta: { title: "Administration" },
      components: { grid: Administration, toolbar: ToolbarAdmin },
    },
    {
      path: "/compliance",
      name: "compliance",
      meta: { title: "Compliance" },
      components: { grid: Compliance, toolbar: ToolbarCompliance },
    },
    {
      path: "/research",
      name: "research",
      meta: { title: "Research" },
      components: { grid: Research, toolbar: ToolbarResearch },
    },
    // Resources
    {
      path: "/statuscodes",
      name: "statuscodes",
      meta: { title: "Status Codes" },
      components: { grid: Resources, toolbar: ToolbarResources },
      props: {
        grid: {
          resourceType: "status_codes",
        },
      },
    },
    {
      path: "/typecodes",
      name: "typecodes",
      meta: { title: "Type Codes" },
      components: { grid: Resources, toolbar: ToolbarResources },
      props: {
        grid: {
          resourceType: "type_codes",
        },
      },
    },
    {
      path: "/communications",
      name: "communications",
      meta: { title: "Communications" },
      components: { grid: Resources, toolbar: ToolbarResources },
      props: {
        grid: {
          resourceType: "communications",
        },
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  document.title = to?.meta.title ? `PCOC | ${to.meta.title}` : "PCOC"
  const datasource = store.getters.datasource
  datasource.cancelToken && datasource.cancel()
  next()
})

export default router
