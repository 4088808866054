<template>
  <div
    class="w-100"
    style="font-size: 1rem; font-family: var(--font-family-sans-serif);"
    data-testid="unlink-panel"
  >
    <div
      class="card-header mb-2 border-right d-flex justify-content-between align-items-center"
    >
      <h4 class="mb-0">
        Unlink
      </h4>
      <PanelControls :show-collapse="false"></PanelControls>
    </div>

    <form class="p-2">
      <ErrorAlert
        v-if="error"
        :error="error"
        @close="error = null"
      ></ErrorAlert>
      <label for="existing" class="mb-1 ml-1">Existing Groups:</label>
      <GroupsViewer
        id="existing"
        :groups-key="groupsKey"
        action="unlink"
        :groups-style="{ width: '100%', height: '30vh' }"
        :transactions-style="{ width: '100%', height: '45vh' }"
        @unlink="unlinkTransaction"
      />
    </form>
  </div>
</template>

<script>
import GroupsViewer from "./GroupsViewer"
import ErrorAlert from "./ErrorAlert"
import PanelControls from "./PanelControls"
export default {
  name: "PanelUnlink",
  components: {
    GroupsViewer,
    ErrorAlert,
    PanelControls,
  },
  data() {
    return { error: null, groupsKey: 0 }
  },
  mounted() {
    /** Triggers the GroupsGrid in GroupsViewer to refresh groups */
    this.params.api.addEventListener("toolPanelVisibleChanged", e => {
      if (e.source === "unlink") this.refreshGroups()
    })
  },
  methods: {
    async unlinkTransaction({ groupId, transactionId }) {
      const path = `/groups/${groupId}/unlink/`
      const body = { transactions: [transactionId] }
      const [error, _response] = await this.$api.put(path, body)
      if (error) {
        this.error = error
      } else {
        this.refreshGroups()
        this.params.api.purgeServerSideCache()
      }
    },
    /** Trigger GridViewer rerender so that groups and transactions reload */
    refreshGroups() {
      this.groupsKey += 1
    },
  },
}
</script>

<style></style>
