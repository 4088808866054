import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    gridApi: null,
    columnApi: null,
    search: null,
  },
  mutations: {
    setGridApis(state, payload) {
      Vue.set(state, "gridApi", payload.gridApi)
      Vue.set(state, "columnApi", payload.columnApi)
    },
    setSearch(state, payload) {
      state.search = payload
    },
  },
  actions: {
    gridInit({ commit }, payload) {
      commit("setGridApis", payload)
    },
  },
  getters: {
    datasource(state) {
      return state.gridApi?.rowModel?.datasource || {}
    },
  },
  modules: {},
})
