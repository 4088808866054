<template>
  <div class="w-100">
    <div class="d-flex flex-wrap align-items-center mb-2">
      <button
        :data-testid="`${codeType}-button`"
        class="btn btn-sm btn-primary mr-2 mb-1 px-3"
        title="Select codes"
        @click.prevent="toggleGrid"
      >
        <span class="toggle" :class="{ open: showGrid }">
          ▾
        </span>
      </button>
      <span v-if="sortedCodes.length === 0" class="ml-1 text-muted">None</span>
      <span
        v-for="code in sortedCodes"
        :key="code"
        class="code-badge mb-1"
        :class="{ inactive: !isActive(code) }"
      >
        {{ code }}
      </span>
    </div>
    <div v-show="showGrid" :data-testid="`${codeType}-grid`">
      <div class="input-group input-group-sm mb-1">
        <div class="input-group-prepend">
          <button class="btn btn-secondary" type="button" disabled>
            <SearchIcon class="feather-sm"></SearchIcon>
          </button>
        </div>
        <input
          v-model="quickFilter"
          class="form-control"
          placeholder="Quick filter..."
          type="text"
          @input="gridApi.setQuickFilter(quickFilter)"
        />
      </div>
      <AgGridVue
        :id="codeType"
        :style="{ width: '100%', height: '300px' }"
        class="ag-theme-balham"
        :grid-options="gridOptions"
        :debug="false"
        @grid-ready="onGridReady"
        @selection-changed="onSelectionChanged"
        @row-selected="onRowSelected"
      />
    </div>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue"
import columnDefs from "./Grid/resourceColumnDefs"
import { cloneDeep } from "lodash"
import SearchIcon from "../assets/search.svg"
export default {
  components: {
    AgGridVue,
    SearchIcon,
  },
  props: {
    codeType: {
      type: String,
      required: true,
      validator: x => ["type_codes", "status_codes"].includes(x),
    },
    value: {
      type: Array,
      required: true,
    },
    showInactive: {
      type: Boolean,
      default: false,
    },
    blanksOption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const colDefs = cloneDeep(columnDefs[this.codeType])
    colDefs[0].checkboxSelection = true
    colDefs[0].suppressMenu = true
    return {
      showGrid: false,
      gridApi: null,
      columnApi: null,
      inactiveCodes: [],
      quickFilter: "",
      gridOptions: {
        columnDefs: colDefs,
        rowMultiSelectWithClick: true,
        rowData: null,
        rowSelection: "multiple",
      },
    }
  },
  computed: {
    /** Order codes for badges with active codes first. */
    sortedCodes() {
      return [...this.value].sort(code => (this.isActive(code) ? -1 : 1))
    },
  },
  methods: {
    onGridReady() {
      this.gridApi = this.gridOptions.api
      this.columnApi = this.gridOptions.columnApi
      this.fetchCodes()
    },
    /** Retrieves all status codes, sets up grid with active codes, stores inactive codes for reference. */
    async fetchCodes() {
      const [error, response] = await this.$api.get(this.codeType + "/")
      if (error) {
        this.$alert.fire({ icon: "error", text: error.toString() })
        this.gridApi.showNoRowsOverlay()
      } else if (response.data.length < 1) {
        this.gridApi.showNoRowsOverlay()
      } else {
        this.gridApi.hideOverlay()
        const data = response.data
        const rows = this.showInactive ? data : data.filter(c => c.is_active)
        if (this.blanksOption) rows.unshift({ code: "(Blanks)" })
        this.gridApi.setRowData(rows)
        this.inactiveCodes = data
          .filter(code => !code.is_active)
          .map(code => code.code)
      }
    },
    isActive(code) {
      return !this.inactiveCodes.includes(code)
    },
    /** Selects all codes in the grid included in `value` prop. */
    setSelected() {
      this.gridApi.deselectAll()
      this.gridApi.forEachNode(node => {
        if (this.value.includes(node.data.code)) {
          node.setSelected(true)
        }
      })
    },
    onSelectionChanged() {
      const selected = this.gridApi.getSelectedRows().map(row => row.code)
      const inactive = this.value.filter(x => !this.isActive(x))
      const input = this.showInactive ? selected : [...selected, ...inactive]
      this.$emit("input", input)
    },
    /** Make Blanks option and other options mutually exclusive. */
    onRowSelected({ node }) {
      if (!this.blanksOption) return
      if (node.selected === false) return
      if (node.data.code === "(Blanks)") {
        this.gridApi.forEachNode(node => {
          if (node.data.code !== "(Blanks)") node.setSelected(false)
        })
      } else {
        this.gridApi.getRowNode(0).setSelected(false)
      }
    },
    toggleGrid() {
      this.showGrid = !this.showGrid
      if (this.showGrid) {
        this.setSelected()
        this.gridApi.sizeColumnsToFit()
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.code-badge {
  background-color: var(--primary-light);
  color: white;
  border-radius: 5px;
  font-weight: 500;
  margin: 0 2px;
  padding: 5px;
}
.code-badge.inactive {
  background-color: var(--gray);
}
.toggle {
  transition: transform 0.5s;
  display: inline-block;
}
.toggle.open {
  transform: rotate(-180deg);
}
</style>
