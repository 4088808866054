<template>
  <nav class="nav-vert bg-primary h-100">
    <router-link to="/" title="Home">
      <FeatherIcon></FeatherIcon>
      <strong style="letter-spacing: 0.8px;">PCOC</strong>
    </router-link>
    <router-link to="/administration" title="Administration View">
      <BookIcon></BookIcon><span>Admin</span>
    </router-link>
    <router-link to="/compliance" title="Compliance View">
      <FlagIcon></FlagIcon> <span>Compliance</span>
    </router-link>
    <router-link to="/research" title="Research View">
      <SearchIcon></SearchIcon> <span>Research</span>
    </router-link>
    <hr class="border-light" style="width: 80%;" />
    <router-link to="/statuscodes" title="Status Codes Management">
      <StatusCodesIcon></StatusCodesIcon> <span>Status</span>
    </router-link>
    <router-link to="/typecodes" title="Type Codes Management">
      <TypeCodesIcon></TypeCodesIcon> <span>Type</span>
    </router-link>
    <router-link to="/communications" title="Department Communications">
      <CommunicationsIcon></CommunicationsIcon>
      <span>Comms</span>
    </router-link>
    <a class="mt-auto" href="" @click.prevent="$auth.logout()">
      <LogOutIcon></LogOutIcon>
      <span>Logout</span>
    </a>
  </nav>
</template>

<script>
import BookIcon from "../assets/book.svg"
import FlagIcon from "../assets/flag.svg"
import SearchIcon from "../assets/search.svg"
import FeatherIcon from "../assets/feather.svg"
import LogOutIcon from "../assets/log-out.svg"
import StatusCodesIcon from "../assets/bar-chart-2.svg"
import TypeCodesIcon from "../assets/box.svg"
import CommunicationsIcon from "../assets/message-square.svg"
export default {
  components: {
    BookIcon,
    FlagIcon,
    SearchIcon,
    LogOutIcon,
    FeatherIcon,
    StatusCodesIcon,
    TypeCodesIcon,
    CommunicationsIcon,
  },
  computed: {
    currentEnvironment() {
      const hostname = window.location.hostname
      if (hostname === "localhost") {
        return { name: "Local", color: "primary" }
      } else if (hostname.match("-dev")) {
        return { name: "Development", color: "primary" }
      } else if (hostname.match("-stage")) {
        return { name: "Stage", color: "warning" }
      } else {
        return { name: "Production", color: "accent" }
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.nav-vert {
  align-items: center;
  display: flex;
  flex-direction: column;
}
a {
  align-items: center;
  border-radius: 0;
  color: #dee2e6;
  display: flex;
  flex-direction: column;
  height: 70px;
  width: 70px;
  font-size: 12px;
  justify-content: flex-end;
  padding-bottom: 9px;
}
a.router-link-exact-active {
  background: linear-gradient(90deg, #fff 5%, #4274aa 6%, #26609e 70%);
  color: #fff;
}
a:focus,
a:hover {
  color: #fff !important;
  text-decoration: none;
}
</style>
