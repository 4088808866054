<template>
  <AgGridVue
    :style="{ width, height }"
    class="ag-theme-balham"
    :grid-options="gridOptions"
    :debug="false"
    @grid-ready="onGridReady"
    @row-selected="onRowSelected"
  />
</template>
<script>
import { AgGridVue } from "ag-grid-vue"
import formatters from "../utils/formatters"
export default {
  components: {
    AgGridVue,
  },
  props: {
    groups: {
      required: true,
      type: Array,
    },
    width: {
      type: [String, Number],
      default: () => "100%",
    },
    height: {
      type: [String, Number],
      default: () => "200px",
    },
  },
  data() {
    const columnDefs = [
      {
        headerName: "Group #",
        field: "id",
        sort: "desc",
      },
      {
        headerName: "Description",
        field: "description",
      },
      {
        headerName: "Updated",
        field: "updated_date",
        valueFormatter: ({ value }) => formatters.datetime(value),
      },
      {
        headerName: "Created",
        field: "created_date",
        valueFormatter: ({ value }) => formatters.datetime(value),
      },
      {
        headerName: "Owner",
        field: "created_by",
      },
    ]
    return {
      gridOptions: {
        columnDefs,
        rowData: null,
        rowSelection: "single",
      },
      gridApi: null,
      columnApi: null,
      error: null,
    }
  },
  watch: {
    groups(newVal) {
      if (this.gridApi) {
        this.gridApi.setRowData(newVal)
      }
    },
  },
  methods: {
    onGridReady() {
      this.gridApi = this.gridOptions.api
      this.columnApi = this.gridOptions.columnApi
      this.gridApi.setRowData(this.groups)
      this.gridApi.addEventListener("gridSizeChanged", () => {
        this.gridApi.sizeColumnsToFit()
      })
    },
    onRowSelected(node) {
      this.$emit("change", node.data)
    },
  },
}
</script>
