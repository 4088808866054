<template>
  <div
    v-observe-visibility="syncValue"
    class="pt-1"
    style="font-size: 1rem; font-family: var(--font-family-sans-serif);"
  >
    <div class="form-group mb-2 px-2">
      <label>{{ params.colDef.headerName }} Codes</label>
      <input
        v-model="filter"
        type="text"
        placeholder="Filter options"
        class="form-control form-control-sm mt-1"
      />
    </div>
    <div v-if="!codes.length" class="text-muted text-center pb-3">
      Loading...
    </div>
    <div v-else class="px-2" style="max-height: 400px; overflow-y: scroll;">
      <div class="py-1" @click="toggleBlanks">
        <input :checked="blanksFilter" type="checkbox" />
        <span class="pl-2">(Blanks)</span>
      </div>
      <div
        v-for="code in filteredCodes"
        :key="code.id"
        class="py-1"
        @click="toggle(code)"
      >
        <input v-model="code.selected" type="checkbox" />
        <span class="pl-2" v-text="code.code"></span>
      </div>
    </div>
    <div class="py-1 px-2 d-flex justify-content-end border-top">
      <button class="btn btn-sm btn-outline-primary" @click="clear">
        Clear
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      codes: [],
      filter: "",
      change: false,
      blanksFilter: false,
    }
  },
  computed: {
    ...mapGetters(["datasource"]),
    filteredCodes() {
      const filter = this.filter.toLowerCase()
      return this.codes.filter(c => c.code.toLowerCase().includes(filter))
    },
    selectedCodes() {
      return this.codes.filter(c => c.selected === true).map(c => c.code)
    },
  },
  methods: {
    async fetchCodes() {
      const [err, res] = await this.$api.get(
        this.params.colDef.__metadata__.type + "/",
      )
      if (err) {
        this.$alert(err?.response?.data || err.toString())
      } else if (res.data) {
        this.codes = res.data.map(i => ({ ...i, selected: false }))
      }
    },
    /** Submit any change on close, grab latest state on open. */
    async syncValue(visible) {
      this.filter = ""
      if (!this.codes.length) await this.fetchCodes()
      const field = this.params.colDef.field
      const value = this.datasource.getFilter(field)
      if (visible && value?.raw) {
        this.change = false
        this.codes.forEach(c => (c.selected = value.raw.includes(c.code)))
      } else if (this.change) {
        this.submit()
      }
    },
    toggle(code) {
      this.change = true
      code.selected = !code.selected
      this.blanksFilter = false
    },
    toggleBlanks() {
      this.blanksFilter = !this.blanksFilter
      if (this.blanksFilter) {
        this.codes.forEach(c => (c.selected = false))
      }
      this.change = true
    },
    submit() {
      this.change = false
      const value = this.blanksFilter ? "(Blanks)" : this.selectedCodes
      this.datasource.updateFilter(this.params.colDef, value)
    },
    clear() {
      this.change = true
      this.codes.forEach(c => (c.selected = false))
    },
    isFilterActive() {
      const field = this.params.colDef.field
      return Boolean(this.datasource.getFilter(field))
    },
  },
}
</script>

<style></style>
