<template>
  <div>
    <ErrorAlert v-if="error" :error="error" @close="error = null"></ErrorAlert>
    <GroupsViewerGrid
      data-testid="group-list"
      :groups="groups"
      :groups-style="groupsStyle"
      :height="groupsStyle.height"
      :width="groupsStyle.width"
      @change="selectGroup"
      @error="error = $event"
    />

    <div v-if="action == 'link'" class="d-flex">
      <button
        class="btn btn-primary btn-sm ml-auto mt-2"
        :disabled="!selectedGroup"
        @click.prevent="$emit('link', selectedGroup.id)"
      >
        Link
      </button>
    </div>

    <div v-if="selectedGroup" data-testid="group-transactions" class="mt-2">
      <label for="transactions-in-group" class="my-1 ml-1"
        >Group #{{ selectedGroup.id }}:
        <strong> {{ selectedGroup.description }}</strong></label
      >
      <ClientSideGrid
        id="transactions-in-group"
        :height="transactionsStyle.height"
        :width="transactionsStyle.width"
        :row-selection="action == 'unlink'"
        :transactions="transactions"
        :style="transactionsStyle"
        @row-selected="onSelectRow"
      />
      <div v-if="action == 'unlink'" class="d-flex">
        <button
          class="btn btn-primary btn-sm ml-auto mt-2"
          :disabled="!selectedGroup || !selectedTransaction"
          data-testid="group-unlink"
          @click.prevent="unlink"
        >
          Unlink
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import GroupsViewerGrid from "./GroupsViewerGrid"
import ClientSideGrid from "./ClientSideGrid"
import ErrorAlert from "./ErrorAlert"
export default {
  components: { GroupsViewerGrid, ErrorAlert, ClientSideGrid },
  props: {
    /** Increment to trigger refresh of groups and thier transactions */
    groupsKey: {
      type: Number,
      default: () => 0,
    },
    groupsParams: {
      type: Object,
      default: () => ({}),
    },
    action: {
      default: null,
      type: [String, null],
      validator: v => ["link", "unlink", null].includes(v),
    },
    groupsStyle: {
      type: Object,
      default: () => ({ height: "200px", width: "100%" }),
    },
    transactionsStyle: {
      type: Object,
      default: () => ({ height: "200px", width: "100%" }),
    },
  },
  data() {
    return {
      error: null,
      groups: [],
      selectedGroup: null,
      selectedTransaction: null,
      transactions: [],
      groupSelecting: false, // needed to prevent async api call stacking
    }
  },
  watch: {
    /** Refetch groups and the selected group's transactions as they have changed. */
    async groupsKey() {
      await this.fetchGroups()
      await this.fetchSelectedGroup()
    },
    async groupsParams() {
      await this.fetchGroups()
      await this.fetchSelectedGroup()
    },
  },
  mounted() {
    this.fetchGroups()
  },
  methods: {
    /** Populate the groups array from which several grids are derived */
    async fetchGroups() {
      const [error, response] = await this.$api.get("/groups/", {
        params: this.groupsParams,
      })
      if (error) {
        this.error = error
      } else {
        this.groups = response.data
      }
    },
    selectGroup(group) {
      if (!this.groupSelecting) {
        this.groupSelecting = true
        this.transactions = []
        this.selectedGroup = group
        this.fetchSelectedGroup()
      }
    },
    async fetchSelectedGroup() {
      if (!this.selectedGroup) return
      const [error, response] = await this.$api.get(
        `transactions/?annotation__groups__id=${this.selectedGroup.id}`,
      )
      if (error) {
        this.error = error
      } else {
        this.transactions = response.data
        this.groupSelecting = false
      }
    },
    onSelectRow(node) {
      this.selectedTransaction = node.data
    },
    unlink() {
      this.$emit("unlink", {
        groupId: this.selectedGroup.id,
        transactionId: this.selectedTransaction.id,
      })
      this.selectedTransaction = null
    },
  },
}
</script>
