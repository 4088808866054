<template>
  <div class="grid-container">
    <div class="toolbar-area">
      <ToolbarBase></ToolbarBase>
    </div>
    <div class="nav-area">
      <Navigation />
    </div>
    <div class="grid-area" data-testid="grid-area">
      <AuthGuard>
        <router-view :key="$route.path" name="grid"> </router-view>
      </AuthGuard>
    </div>
  </div>
</template>

<script>
import "@uarizona-fnsv/bootstrap/dist/bootstrap.css"
import Navigation from "./components/Navigation"
import AuthGuard from "@uarizona-fnsv/vue-doggo/AuthGuard"
import ToolbarBase from "./components/ToolbarBase"

export default {
  components: {
    Navigation,
    AuthGuard,
    ToolbarBase,
  },
  async beforeMount() {
    window.addEventListener("beforeunload", event => {
      this.$store.getters.datasource.cancel()
      // Chrome requires returnValue to be set.
      event.returnValue = ""
    })
    await this.$auth.login()
  },
}
</script>

<style lang="postcss">
.grid-container {
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-template-rows: 3rem 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "nav toolbar"
    "nav grid";
  height: 100vh;
}
.nav-area {
  grid-area: nav;
}
.toolbar-area {
  grid-area: toolbar;
}
.grid-area {
  grid-area: grid;
  background-color: #eee;
}

/* Feather icon SVG sprites */
.feather {
  height: 24px;
  width: 24px;
}
.feather-lg {
  height: 34px;
  width: 34px;
}
.feather-sm {
  height: 18px;
  width: 18px;
  margin-bottom: 2px;
}

/* Ag-Grid Styles */
.ag-side-bar > .ag-tool-panel-wrapper {
  width: 550px;
}
.ag-filter-toolpanel-instance {
  font-family: var(--font-family-sans-serif);
  font-size: 1rem;
}
.ag-filter-toolpanel-header {
  font-weight: 600;
}
.ag-tool-panel-wrapper {
  align-items: flex-start;
}
.ag-column-panel {
  align-self: stretch;
}

/* vue-month-picker */
.month-picker-input {
  color: var(--gray-dark);
  font-size: 1rem !important;
  padding: 0.375rem 0.75rem !important;
}
.month-picker__container {
  font-size: 0.8rem;
  width: 300px !important;
  /* fix transparency and clipping */
  background-color: var(--white);
  z-index: 1;
}
.month-picker__month {
  /* fix month buttons not filling panel */
  flex-basis: calc(33.333%) !important;
}
.month-picker__month.selected {
  background-color: var(--primary-light) !important;
}
</style>
