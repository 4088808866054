<template>
  <div>
    <Grid :grid-options="gridOptions" name="home"></Grid>
  </div>
</template>

<script>
import Grid from "../components/Grid"
import getGridOptions from "../components/Grid/gridOptions"
export default {
  components: {
    Grid,
  },
  data() {
    return {
      gridOptions: getGridOptions("home"),
    }
  },
}
</script>
