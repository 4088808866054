<template>
  <AgGridVue
    :style="{ width: '100%', height: '200px' }"
    class="ag-theme-balham"
    :grid-options="gridOptions"
    :debug="false"
    @grid-ready="onGridReady"
    @row-selected="rowSelected"
  />
</template>
<script>
import { AgGridVue } from "ag-grid-vue"
export default {
  components: {
    AgGridVue,
  },
  props: {
    people: {
      type: [Array, String],
      required: true,
    },
  },
  data() {
    return {
      gridOptions: {
        columnDefs: [
          {
            headerName: "Name",
            field: "cn",
            width: 400,
          },
          {
            headerName: "Department Name",
            field: "employeePrimaryDeptName",
          },
          {
            headerName: "Department Number",
            field: "employeePrimaryDept",
          },
          {
            headerName: "EmplID",
            field: "emplid",
          },
          { headerName: "NetID", field: "uid" },
          { headerName: "Affiliation", field: "eduPersonPrimaryAffiliation" },
        ],
        rowSelection: "single",
        rowData: null,
      },
      gridApi: null,
      columnApi: null,
    }
  },
  watch: {
    people(newVal) {
      if (typeof newVal === "object") {
        this.gridApi?.setRowData(newVal)
      } else {
        this.gridApi.setRowData([])
      }
    },
  },
  methods: {
    onGridReady() {
      const rowData = typeof this.people === "object" ? this.people : []
      this.gridApi = this.gridOptions.api
      this.columnApi = this.gridOptions.columnApi
      this.gridApi.setRowData(rowData)
      this.gridApi.addEventListener("gridSizeChanged", () => {
        this.gridApi.sizeColumnsToFit()
      })
    },
    rowSelected(node) {
      if (!node.node.selected) return
      this.$emit("input", node.data)
    },
  },
}
</script>
