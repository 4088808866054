<template>
  <div
    class="w-100"
    style="font-size: 1rem; font-family: var(--font-family-sans-serif);"
  >
    <SlideXRightTransition>
      <div
        v-show="sliderState.open"
        class="slider border-bottom border-left"
        style="left: calc(-30vw  - 1px);"
      >
        <div class="card-header">
          <h5 class="mb-0 d-flex align-items-center">
            <div>People</div>
            <span class="badge ml-auto mr-2 border border-secondary">
              {{ badgeText }}
            </span>
            <button
              class="btn"
              type="button"
              aria-label="Close"
              title="Close"
              @click="$emit('close')"
            >
              <span class="close">&times;</span>
            </button>
          </h5>
        </div>
        <div class="card-body">
          <SearchPeopleGrid
            v-if="sliderState.data"
            :people="sliderState.data"
            @input="copyPerson"
          />
        </div>
      </div>
    </SlideXRightTransition>
  </div>
</template>

<script>
import { SlideXRightTransition } from "vue2-transitions"
import SearchPeopleGrid from "./SearchPeopleGrid.vue"
export default {
  components: {
    SearchPeopleGrid,
    SlideXRightTransition,
  },
  props: {
    // TODO: break up into inividual props.
    sliderState: {
      required: true,
      type: Object,
    },
  },
  computed: {
    badgeText() {
      if (!this.sliderState.data) return "..."
      if (typeof this.sliderState.data === "string")
        return this.sliderState.data
      else {
        return `${this.sliderState.data.length} ${
          this.sliderState.data.length > 1 ? "people" : "person"
        }`
      }
    },
  },
  methods: {
    copyPerson(person) {
      this.$emit("input", {
        searchField: this.sliderState.searchField,
        ...person,
      })
    },
  },
}
</script>

<style lang="postcss" scoped>
.slider {
  background-color: #fff;
  position: absolute;
  width: 30vw;
  top: 0;
}
</style>
