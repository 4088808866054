import Vue from "vue"
import Doggo from "@uarizona-fnsv/vue-doggo"
import Swal from "sweetalert2"
import axios from "axios"
import { LicenseManager } from "ag-grid-enterprise"
import * as Sentry from "@sentry/vue"

import VueObserveVisibility from "vue-observe-visibility"

import App from "./App.vue"
import router from "./router"
import api from "./utils/api"
import store from "./store"

LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-043610 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( University of Arizona Financial Services Office )_is_granted_a_( Multiple Applications )_Developer_License_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 11 August 2024 )____[v2]_MTcyMzMzMDgwMDAwMA==8f3ff989bdbc8492f9222ce0c33cf38c",
)

// Sentry configuration
function getEnvironment() {
  const hostname = window.location.hostname
  if (hostname.includes("-dev")) return "dev"
  if (hostname.includes("cloudfront.net")) return "review"
  return "prod"
}

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    environment: getEnvironment(),
    dsn: process.env.SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/pcoc-dev\.fnsv\.arizona\.edu/,
      /^https:\/\/pcoc\.fnsv\.arizona\.edu/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

Vue.use(Doggo, {
  amplify: {
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    domain: process.env.VUE_APP_COGNITO_DOMAIN,
    redirectSignIn: process.env.VUE_APP_COGNITO_SIGN_IN_URL,
  },
  disable: process.env.VUE_APP_DISABLE_AUTH,
})

Vue.use(VueObserveVisibility)

Vue.config.productionTip = false

// Add global methods for making requests
Vue.prototype.$http = axios
Vue.prototype.$api = api // For internal API, comes with CSRF token.
Vue.prototype.$alert = Swal

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")
