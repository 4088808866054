<template>
  <AgGridVue
    :style="{ width, height }"
    class="ag-theme-balham"
    :grid-options="gridOptions"
    :debug="false"
    @grid-ready="onGridReady"
    @row-selected="rowSelected"
  />
</template>
<script>
import { AgGridVue } from "ag-grid-vue"
import getGridOptions from "./Grid/gridOptions"
export default {
  components: {
    AgGridVue,
  },
  props: {
    transactions: {
      type: Array,
      required: true,
    },
    rowSelection: {
      type: Boolean,
      default: () => false,
    },
    width: {
      type: [String, Number],
      default: () => "100%",
    },
    height: {
      type: [String, Number],
      default: () => "200px",
    },
  },

  data() {
    const go = getGridOptions()
    const columnDefs = go.columnDefs
    const columnTypes = go.columnTypes
    return {
      gridOptions: {
        columnDefs,
        columnTypes,
        rowSelection: this.rowSelection ? "single" : "none",
        rowData: null,
      },
      gridApi: null,
      columnApi: null,
    }
  },
  watch: {
    transactions(newVal) {
      this.gridApi?.setRowData(newVal)
    },
  },
  methods: {
    onGridReady() {
      this.gridApi = this.gridOptions.api
      this.columnApi = this.gridOptions.columnApi
      this.gridApi.setRowData(this.transactions)
    },
    rowSelected(node) {
      this.$emit("row-selected", node)
    },
  },
}
</script>
