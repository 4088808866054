<template>
  <div class="w-100">
    <div class="mb-2">
      <div class="d-flex align-items-center">
        <button
          class="btn btn-sm btn-primary mr-2 px-3"
          @click.prevent="toggleGrid"
        >
          <span class="toggle" :class="{ open: showGrid }">
            ▾
          </span>
        </button>
        <input
          v-if="showGrid"
          type="text"
          class="form-control form-control-sm"
          placeholder="Filter"
          @input="gridApi.setQuickFilter($event.target.value)"
        />
        <span v-else>Templates</span>
      </div>
    </div>
    <div v-show="showGrid">
      <AgGridVue
        :style="{ width: '100%', height: '200px' }"
        class="ag-theme-balham"
        :grid-options="gridOptions"
        :debug="false"
        @grid-ready="onGridReady"
        @selection-changed="onSelectionChanged"
      />
    </div>
    <div
      v-if="selected && selected.length"
      class="alert alert-light mt-3 w-100 text-dark"
    >
      <p style="white-space: pre-wrap;">{{ selectedText }}</p>
      <div class="d-flex justify-content-end">
        <button class="btn btn-sm btn-light" @click.prevent="copy">Copy</button>
      </div>
    </div>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue"
export default {
  components: {
    AgGridVue,
  },
  data() {
    return {
      showGrid: false,
      selected: [],
      gridApi: null,
      columnApi: null,
      gridOptions: {
        columnDefs: [
          {
            field: "text",
          },
        ],
        rowData: null,
        rowSelection: "multiple",
        headerHeight: 0,
      },
    }
  },
  computed: {
    selectedText() {
      return this.selected
        .map(x => x.text)
        .filter(x => x)
        .join("\n\n")
    },
  },
  methods: {
    onGridReady() {
      this.gridApi = this.gridOptions.api
      this.columnApi = this.gridOptions.columnApi
      this.fetchCommunications()
    },
    async fetchCommunications() {
      const [error, response] = await this.$api.get(`communications/`)
      if (error) {
        this.$alert.fire({ icon: "error", text: error.toString() })
        this.gridApi.showNoRowsOverlay()
      } else if (response.data.length < 1) {
        this.gridApi.showNoRowsOverlay()
      } else {
        this.gridApi.hideOverlay()
        this.gridApi.setRowData(response.data)
      }
    },
    onSelectionChanged() {
      this.selected = this.gridApi.getSelectedRows()
    },
    toggleGrid() {
      this.showGrid = !this.showGrid
      this.selected = null
      if (this.showGrid) this.gridApi.sizeColumnsToFit()
    },
    copy() {
      this.$emit("change", this.selectedText)
      this.gridApi.deselectAll()
      this.selected = []
      this.showGrid = false
    },
  },
}
</script>

<style lang="postcss" scoped>
.toggle {
  transition: transform 0.5s;
  display: inline-block;
}
.toggle.open {
  transform: rotate(-180deg);
}
</style>
