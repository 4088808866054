<template>
  <datalist :id="id">
    <option value="(Blanks)">(Blanks)</option>
    <option v-for="suggest in suggestions" :key="suggest" :value="suggest">{{
      suggest
    }}</option>
  </datalist>
</template>

<script>
import { debounce } from "lodash"
import { mapGetters } from "vuex"
export default {
  props: {
    id: { type: String, required: true },
    basePath: { type: String, default: "transactions" },
    limit: { type: Number, default: 10 },
    field: { type: String, required: true },
    value: { type: [String, Number], default: null },
    focus: { type: Boolean, default: false },
  },
  data: function() {
    return { suggestions: [] }
  },
  computed: {
    ...mapGetters(["datasource"]),
  },
  watch: {
    value() {
      this.fetchSuggestions()
    },
    focus(value) {
      if (value) {
        this.fetchSuggestions()
      }
    },
  },
  methods: {
    fetchSuggestions: debounce(async function() {
      const [_err, res] = await this.$api.get(
        `${this.basePath}/autocomplete/`,
        {
          params: {
            ...this.datasource.params,
            field: this.field.replace("annotation.", ""),
            value: this.value,
            limit: this.limit,
          },
        },
      )
      this.suggestions = res.data
    }, 350),
  },
}
</script>

<style></style>
